import React from 'react'
import Layout from '../components/Layout'


const privacypolicy = () => {
  return (
    <Layout>
    <div style={{padding: '5vw'}}>
     </div>
    </Layout>
  )
}

export default privacypolicy
